import '../App.css';
import Button from '@mui/material/Button';
import { hostRedirectUri, hostUrl, clientId, clientSecret } from '../spotify/consts';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Randomstring from 'randomstring'
import { baseUrl } from '../spotify/consts';
import querystring from 'querystring';
import { CircularProgress } from '@mui/material';

function HostThanks() {
	// const { id } = useParams();
	// const [ids, setIds] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState();
	const [refresh, setRefresh] = useState();
	const [oj_id, setOjId] = useState();
	const [isInDb, setIsInDb] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);
	const ojId = String(Randomstring.generate(12));
	// useEffect(() => {
	// 	fetch("https://koa6vfhfme.execute-api.us-east-2.amazonaws.com/production", {
	// 	  method: 'POST',
	// 	  // mode: 'no-cors',
	// 	  headers: {
	// 		// 'Content-Type': 'application/json',
	// 		// 'Access-Control-Allow-Origin': '*',
	// 		// "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
	// 		// "Access-Control-Allow-Credentials": "true",
	// 		// 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
	// 		// 'Referrer-Policy': 'no-referrer-when-downgrade'
	// 	  },
	// 	  body: JSON.stringify({get_ids:'true'})
	// 	}).then((r) => r.json())
	// 	.then((res) => { 
	// 		console.log(res);
	// 		setIsLoading(false);
	// 		console.log(res.ids);
	// 		setIds(res.ids);
	// 	})
	// 	.catch((err) => console.log(err))
	//   }, []
	// )

	useEffect(() => {
		const hash = window.location.hash
		let url = window.location.href;
		// let token = window.localStorage.getItem("token")
		// let token = null;
		// let token;
		// let refresh;
		let code;
		// console.log(url.includes('code'));

		// if (!token && hash) {
		// 	token = hash.substring(1).split("&").find(elem => elem.startsWith("code")).split("=")[1];
		// 	refresh = hash.substring(1).split("&").find(elem => elem.startsWith("refresh_token")).split("=")[1];
	
		// 	window.location.hash = ""
		// 	window.localStorage.setItem("token", token)
		// }
		
		if (url.includes('code')) {
			code = url.split("?")[1].split('=')[1];
			// console.log('the url is ' + code);
			fetch(`https://accounts.spotify.com/api/token?grant_type=authorization_code&code=${code}&redirect_uri=${hostRedirectUri}`, {
				method: 'post',
				// url: 'https://accounts.spotify.com/api/token',
				// data: querystring.stringify({
				//   grant_type: 'authorization_code',
				//   code: code,
				//   redirect_uri: hostRedirectUri
				// }),
				headers: {
				  'content-type': 'application/x-www-form-urlencoded', 
				  Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
				},
			  }
			).then((response) => response.json() )
			// .then((res) => {
			// 	// token = res.access_token;
			// 	setToken(res.access_token);
			// 	// refresh = res.refresh_token;
			// 	setRefresh(res.refresh_token);
			// 	console.log(res.access_token, res.refresh_token);
			// 	// console.log(token, refresh);
			// 	// console.log(res);
			// })
			.then((r) => {
				setOjId(ojId);
				fetch(hostUrl, {
							method: 'POST',
							// mode: 'no-cors',
							headers: {
								// 'Content-Type': 'application/json',
								// 'Access-Control-Allow-Origin': '*',
								// "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
								// "Access-Control-Allow-Credentials": "true",
								// 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
								// 'Referrer-Policy': 'no-referrer-when-downgrade'
							},
							body: JSON.stringify({
								id: ojId.toLowerCase(),
								access_token: r.access_token,
								refresh_token: r.refresh_token,
								redirect_uri: hostRedirectUri
							})
					}
				)	
					.then((res) => res.json())
					.then((r) => {
						console.log('coming back from my api' + r)
					})
					.then((r) => setHasLoaded(true)).then((r) => console.log(hasLoaded))
					.catch((e) => console.log('err ' + e))
				}
			)
			
		} else {
			console.log("didn't send info to db");
		}
		}, []
	)
  
	return (
		<> 
			{
				!hasLoaded &&
				<div className='loading-screen-parent'>
					<CircularProgress className='loading'/>
				</div>
			}
			{	hasLoaded &&
				// (!isLoading && idInDb) &&
				<div id='top-level'>
					<div id='over' >
					<img src="./assets/logo.png" alt='logo' className='resize'></img>
					</div>
					<div className='host-text'> Thanks for signing Up! </div>
					<CopyToClipboard text={baseUrl + oj_id}>
						<div className='copy-button-parent'>
							<Button className='copy-button' variant='contained'>Copy Link to Clipboard</Button>
						</div>
					</CopyToClipboard>
				</div>
			}
		</>
	);
}

export default HostThanks;


// {"statusCode": 200, "body": {"msg": "User uwgt2xm7ki3gpeknlzdm9vpbg's songs added to spotify:playlist:57syMvNlchJQDkIB97J0w0.", "song_ids": ["spotify:track:4DuUwzP4ALMqpquHU0ltAB", "spotify:track:2YpeDb67231RjR0MgVLzsG", "spotify:track:6ap9lSRJ0iLriGLqoJ44cq", "spotify:track:696DnlkuDOXcMAnKlTgXXK", "spotify:track:4FFC7McrnnwAWKbAqbckTP", "spotify:track:6fujklziTHa8uoM5OQSfIo", "spotify:track:527k23H0A4Q0UJN3vGs0Da", "spotify:track:5qHirGR7M9tdm6C17DlzSY", "spotify:track:7eBqSVxrzQZtK2mmgRG6lC", "spotify:track:0nbXyq5TXYPCO7pr3N8S4I"]}}