import '../App.css';
import Button from '@mui/material/Button';
import { loginHostUrl } from '../spotify/consts';
import { useState, useEffect } from 'react';


function HostPage() {

	// const [token] = useState("");
	// const [ids, setIds] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState();

	// useEffect(() => {
	// 	fetch("https://koa6vfhfme.execute-api.us-east-2.amazonaws.com/production", {
	// 	  method: 'POST',
	// 	  // mode: 'no-cors',
	// 	  headers: {
	// 		// 'Content-Type': 'application/json',
	// 		// 'Access-Control-Allow-Origin': '*',
	// 		// "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
	// 		// "Access-Control-Allow-Credentials": "true",
	// 		// 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
	// 		// 'Referrer-Policy': 'no-referrer-when-downgrade'
	// 	  },
	// 	  body: JSON.stringify({get_ids:'true'})
	// 	}).then((r) => r.json())
	// 	.then((res) => { 
	// 		console.log(res);
	// 		setIsLoading(false);
	// 		console.log(isLoading);
	// 		setIds(res.ids)
	// 	})
	// 	.catch((err) => console.log(err))
	//   }, []
	// )

	// useEffect(() => {
	// 	const hash = window.location.hash
	// 	let token = window.localStorage.getItem("token")
	
	// 	if (!token && hash) {
	// 		token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]
	
	// 		window.location.hash = ""
	// 		window.localStorage.setItem("token", token)
	// 	}
	
	// 	setToken(token);
	// 	}, []
	// )
  
	return (
		<> 
			{
				// !isLoading &&
				<div id='top-level'>
					<div id='over' >
					<img src="./assets/logo.png" alt='logo' className='resize'></img>
					</div>
					<div className='host-text'> You're a Host! </div>
					<div className='button-parent'>
					<Button variant='contained' href={loginHostUrl} className='button' >
						Sign into Spotify to Start The Party!
					</Button>
					</div>
				</div>
			}
		</>
	);
}

export default HostPage;