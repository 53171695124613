import '../App.css';
import Button from '@mui/material/Button';
import { clientUrl, hostUrl, loginUrl } from '../spotify/consts';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";


function ClientPage() {
	const { id } = useParams();
	// const [token, setToken] = useState("");
	const [ids, setIds] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isInDb, setIsInDb] = useState(false);
  
	useEffect(() => {
		fetch(hostUrl, {
		  method: 'POST',
		  // mode: 'no-cors',
		  headers: {
			// 'Content-Type': 'application/json',
			// 'Access-Control-Allow-Origin': '*',
			// "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
			// "Access-Control-Allow-Credentials": "true",
			// 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
			// 'Referrer-Policy': 'no-referrer-when-downgrade'
		  },
		  body: JSON.stringify({get_ids:'true'})
		}).then((r) => r.json())
		.then((res) => { 
			console.log(res);
			setIsLoading(false);
			// console.log(isLoading);
			console.log(res.ids);
			setIds(res.ids);
			for (let i = 0; i < res.ids.length; i ++) {
				if (res.ids[i].id === id) {
					setIsInDb(true);
					break;
				}
			}
		})
		.catch((err) => console.log(err))
	  }, []
	)
	  
	useEffect(() => {
		const url = window.location.href;
		const urlsplit = url.split('/');
		const uid = urlsplit[urlsplit.length - 1];
		window.localStorage.setItem("uid", uid)
		console.log(uid)
	}, [])
  
	return (
		<> 
			{
				(!isLoading && isInDb )&&
				<div id='top-level'>
					<div id='over' >
						<img src="./assets/logo.png" alt='logo' className='resize'></img>
					</div>
					<div className='button-parent'>
						<Button variant='contained' href={loginUrl} className='button' >
							Sign into Spotify to Start The Party!
						</Button>
					</div>
				</div>
			}
		</>
	);
}

export default ClientPage;