export const baseUrl = "https://www.onejam.xyz/";
// export const baseUrl = "http://localhost:3000/";

export const authEndpoint = 'https://accounts.spotify.com/authorize';
export const redirectUri = baseUrl + "thanks";
export const hostRedirectUri = baseUrl + "hostthanks";
export const clientId = "1919b4ee2be3488eb8a2bffca3d3e52a";
export const clientSecret = "735adb72cde3485cb15a304e1ddfee69";
export const scopes = ['playlist-read-private', 'playlist-read-collaborative', 'playlist-modify-private', 'playlist-modify-public', 'user-top-read'];

export const loginUrl = `${authEndpoint}?
	client_id=${clientId}
	&redirect_uri=${redirectUri}
	&scope=${scopes.join("%20")}
	&response_type=token
	&show_dialog=true`;

// export const loginHostUrl = `${authEndpoint}?
// 	client_id=${clientId}
// 	&redirect_uri=${hostRedirectUri}
// 	&scope=${scopes.join("%20")}
// 	&response_type=token
// 	&show_dialog=true`;

// export const loginUrl = `${authEndpoint}?
// 	client_id=${clientId}
// 	&redirect_uri=${redirectUri}
// 	&scope=${scopes.join("%20")}
// 	&response_type=code
// 	&show_dialog=true`;

export const loginHostUrl = `${authEndpoint}?
	client_id=${clientId}
	&redirect_uri=${hostRedirectUri}
	&scope=${scopes.join("%20")}
	&response_type=code
	&show_dialog=true`;

// export const clientUrl = "https://g2uela4m2b.execute-api.us-east-2.amazonaws.com/production";
export const clientUrl = "https://api.onejam.xyz/client";
// export const hostUrl = "https://koa6vfhfme.execute-api.us-east-2.amazonaws.com/production";
export const hostUrl = "https://api.onejam.xyz/host";

// https://accounts.spotify.com/authorize?client_id=1919b4ee2be3488eb8a2bffca3d3e52a&redirect_uri=http:/localhost:3000/hostthanks&scope=playlist-read-private%20playlist-read-collaborative%20playlist-modify-private%20playlist-modify-public%20user-top-read&response_type=token&show_dialog=true