import '../App.css';
import Button from '@mui/material/Button';
import { clientUrl, loginUrl } from '../spotify/consts';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { CircularProgress } from '@mui/material';


function ThanksPage() {
	const { id } = useParams();
	const [ids, setIds] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState();
	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		const hash = window.location.hash
		// let token = window.localStorage.getItem("token")
		let token;

		if (!token && hash) {
			token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]
	
			window.location.hash = ""
			window.localStorage.setItem("token", token)
		}
	
		setToken(token);
		console.log('token is!' + token );
		console.log('')
		if (token) {
			fetch(clientUrl, {
				method: 'POST',
				// mode: 'no-cors',
				headers: {
				  // 'Content-Type': 'application/json',
				  // 'Access-Control-Allow-Origin': '*',
				  // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
				  // "Access-Control-Allow-Credentials": "true",
				  // 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
				  // 'Referrer-Policy': 'no-referrer-when-downgrade'
				},
				body: JSON.stringify({
					id: window.localStorage.getItem("uid"),
					access_token: token
				})
			})
				.then((r) => console.log(r)).then(() => setHasLoaded(true))
				.then(() => console.log(hasLoaded))
				.catch((e) => console.log(e))
		}
	})
  
	return (
		<>
		{
			!hasLoaded &&
			<div id='top-level'>
				<div id='over' >
					<img src="./assets/logo.png" alt='logo' className='resize'></img>
				</div>
				<div className='loading-screen-parent'>
					<CircularProgress className='loading'/>
				</div>
			</div>
		}
		{ hasLoaded &&
			<div id='top-level'>
				<div id='over' >
				<img src="./assets/logo.png" alt='logo' className='resize'></img>
				</div>
				<div className='host-text'> Thanks for signing Up! </div>
				<div className='button-parent'>
				{/* <Button variant='contained' href={loginUrl} className='button' >
					Sign into Spotify to Start The Party!
				</Button> */}
				</div>
			</div>
		}
		</>
	);
}
			{/* {
				!hasLoaded &&
				<div className='loading-screen-parent'>
					<CircularProgress className='loading'/>
				</div>
			} */}


export default ThanksPage;