import './App.css';
import Button from '@mui/material/Button';
import { loginUrl } from './spotify/consts';
import { useState, useEffect } from 'react';
import ClientPage from './components/client';
import { Route, Routes } from "react-router-dom";    
import HostPage from './components/host';
import HostThanks from './components/hostThanks';
import ThanksPage from './components/thanks';

function App() {
  // const [token, setToken] = useState("");

  // useEffect(() => {
  //   fetch("https://koa6vfhfme.execute-api.us-east-2.amazonaws.com/production", {
  //     method: 'POST',
  //     // mode: 'no-cors',
  //     headers: {
  //       // 'Content-Type': 'application/json',
  //       // 'Access-Control-Allow-Origin': '*',
  //       // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  //       // "Access-Control-Allow-Credentials": "true",
  //       // 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  //       // 'Referrer-Policy': 'no-referrer-when-downgrade'
  //     },
  //     body: JSON.stringify({get_ids:'true'})
  //   }).then((res) => console.log(res))
  //   .catch((err) => console.log(err))
  // }, [])
  
  // useEffect(() => {
  //   const hash = window.location.hash
  //   let token = window.localStorage.getItem("token")

  //   if (!token && hash) {
  //       token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

  //       window.location.hash = ""
  //       window.localStorage.setItem("token", token)

  //       setToken(token);
  //   }
  //   setToken(token);
  //   if (token) {
  //         fetch("https://koa6vfhfme.execute-api.us-east-2.amazonaws.com/production", {
  //     method: 'POST',
  //     // mode: 'no-cors',
  //     headers: {
  //       // 'Content-Type': 'application/json',
  //       // 'Access-Control-Allow-Origin': '*',
  //       // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  //       // "Access-Control-Allow-Credentials": "true",
  //       // 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  //       // 'Referrer-Policy': 'no-referrer-when-downgrade'
  //     },
  //     body: JSON.stringify({get_ids:'true'})
  //   }).then((res) => console.log(res))
  //   .catch((err) => console.log(err))
  //   }
  // }, [])

  return (
    // <div id='top-level'>
    //   <div id='over' >
    //     <img src="./assets/logo.png" alt='logo' className='resize'></img>
    //     </div>
    //     <div className='button-parent'>
    //     <Button variant='contained' href={loginUrl} className='button' >
    //       Sign into Spotify to Start The Party!
    //     </Button>
    //   </div>
    // </div>
    <>
      <Routes>
        <Route exact path="/" element={<HostPage />}/>
        <Route exact path="/:id" element={<ClientPage />} />
        <Route exact path="/thanks" element={<ThanksPage />} />
        <Route exact path="/hostthanks" element={<HostThanks />} />
      </Routes>
    </>
  );
}

export default App;
